import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6eJNdrub7ZiidosFsHGlOB681Ghcj5w4",
  authDomain: "carma-telematics.firebaseapp.com",
  projectId: "carma-telematics",
  storageBucket: "carma-telematics.appspot.com",
  messagingSenderId: "749598359907",
  appId: "1:749598359907:web:d0f22f939a03bba10ed356",
  measurementId: "G-JKJ9959CRL"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addEmailToFirestore = async (email: string): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, "emails"), {
      email: email,
      timestamp: new Date()
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};