import React, { useRef, useCallback } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import GlobalStyle from './GlobalStyle';
import Mission from './components/Mission';
import FeatureCards from './components/FeatureCars';
import LearnAndSave from './components/LearnAndSave';
import HowItsWorks from './components/HowItsWorks';
import FAQ from './components/FAQ'; 
import SubscribeForm from './components/SubscribeForm'; 
import RotatingContent from './components/RotatingContent'; 
import ImproveSection from './components/ImproveSection'; 
import Safu from './components/Safu'; 
import './assets/fonts/fonts.css';

const App: React.FC = () => {
  const subscribeFormRef = useRef<HTMLDivElement>(null);

  const scrollToSubscribeForm = useCallback(() => {
    subscribeFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div>
      <GlobalStyle />
      <Header />
      <Hero scrollToSubscribeForm={scrollToSubscribeForm} />
      <Mission />
      <RotatingContent scrollToSubscribeForm={scrollToSubscribeForm} />
      <LearnAndSave scrollToSubscribeForm={scrollToSubscribeForm} />
      <Safu />
      <ImproveSection /> 
      <FAQ /> 
      <SubscribeForm ref={subscribeFormRef} /> 
      <Footer />
    </div>
  );
};

export default App;